import React from 'react'
import { Link, graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'

import '../styles/templates/single-job.scss'

const Content = ({ html, data }) => (
  <section className="content-wrapper max-w-screen-xl mx-auto lg:grid grid-cols-2 mb-16 md:px-20 lg:px-32 px-6">
    <div className="col-start-1 col-end-2 flex lg:block justify-between items-start ">
      <div>
        <h2 style={{ color: '#22738d', fontSize: 40 }} className="font-normal">
          Jobs
        </h2>
        <hr className="mb-10 " style={{ borderColor: '#f9d16d', width: 158 }} />
      </div>
      <Link to="/jobs" className="flex items-center mt-5 text-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 11 11"
          className="mr-3"
        >
          <g fill="none" fillRule="evenodd">
            <g stroke="#27393D">
              <g>
                <path
                  d="M0 4.5L10 4.5M6 0L10 4.5 6 9"
                  transform="translate(-121 -266) rotate(-180 66 138)"
                />
              </g>
            </g>
          </g>
        </svg>
        Back
      </Link>
    </div>
    <div style={{ fontSize: 15, lineHeight: 2 }} className="col-start-2 col-end-3 font-light">
      <h3 style={{ color: '#27393d' }} className="font-medium	text-2xl mb-6">
        {data.title}
      </h3>
      <div className="mb-8">
        <p className="m-0">
          <b>Career level </b>- {data.careerLevel}
        </p>
        <p className="m-0">
          <b>Employment type </b>- {data.employmentType}
        </p>
        <p className="m-0">
          <b>Spoken languages </b>- {data.spokenLanguages}
        </p>
        <p className="m-0">
          <b>Address </b>- {data.address}
        </p>
        <p className="m-0">
          <b>Number of vacancies </b>- {data.numberOfVacancies}
        </p>
      </div>
      <div
        style={{ color: '#242424' }}
        className="mb-10"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="flex justify-center md:justify-start">
        <Link to={`/apply/${data.title.toLowerCase().split(' ').join('-')}`}>
          <Button style={{ height: 54, boxSizing: 'border-box' }} isDark>
            <span className="text-sm">APPLY NOW</span>
          </Button>
        </Link>
      </div>
    </div>
  </section>
)

const SingleJob = ({ data: graphqlData, location }) => {
  const { jobDescriptionNode, ...data } = graphqlData.datoCmsJobItem
  return (
    <Layout className="single-job-page" pathname={location.pathname} simpleHeader alwaysBlack>
      <SEO title={`Jobs - ${data.title}`} />
      <Content html={jobDescriptionNode.childMarkdownRemark.html} data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    datoCmsJobItem(id: { eq: $id }) {
      address
      careerLevel
      employmentType
      id
      jobDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      title
      spokenLanguages
      numberOfVacancies
    }
  }
`

export default SingleJob
